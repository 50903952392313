import { FC, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { Box, useTheme } from "@mui/material";
import {
  types,
  utils,
  toast,
  AddUserForm,
  DeleteConfirmationModal,
} from "@vilocnv/allsetra-core";
import { useParams } from "react-router-dom";
import { FormikHelpers } from "formik";

// DATA
import { useAppDispatch, useAppSelector, useDispatchOnMount } from "hooks";
import {
  createUserAndAssociateToAccountThunk,
  editUserFromAccountThunk,
  getAccountAssociatedUsersThunk,
  getAccountObjectsThunk,
  getAllKeysByAccountThunk,
  getAllRolesThunk,
  getGooglePlacesThunk,
  removeUserFromAccountThunk,
} from "app/features";
import { SignalRService } from "app/data/services";
import {
  selectAccountKeysState,
  selectAccountObjects,
  selectAllRoles,
  selectDashboardGooglePlacesState,
} from "app/data/selectors";
import { ALARM_PRIORITY } from "app/data/constants";

export type Props = {
  toggleAddUserModal: any;
  accountId: string | null;
  setAddUserModal: any;
  addUserModal: any;
  selectedUser: any;
  deleteUserModal: any;
  setDeleteUserModal: any;
};

const AddUserFormWrapper: FC<Props> = ({
  toggleAddUserModal,
  accountId,
  setAddUserModal,
  addUserModal,
  selectedUser,
  deleteUserModal,
  setDeleteUserModal,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const params = useParams();

  const {
    accountKeys,
    accountAvailableKeys,
    accountKeysLoading,
    accountAvailableKeysLoading,
  } = useAppSelector(selectAccountKeysState);

  const { accountObjects, accountObjectsLoading } =
    useAppSelector(selectAccountObjects);
  const { googlePredictatedPlacesLoading, googlePredictatedPlaces } =
    useAppSelector(selectDashboardGooglePlacesState);

  const roles = useAppSelector(selectAllRoles);

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useDispatchOnMount(getAllRolesThunk, roles.length ? undefined : true);

  useDispatchOnMount(getAllKeysByAccountThunk, accountId);

  const filteredKeys = useMemo(() => {
    if (isEmpty(selectedUser)) return accountAvailableKeys;

    const formKey =
      accountKeys &&
      accountKeys.find((key) => {
        // @ts-ignore
        return selectedUser.key === key.uniqueId;
      });

    return isEmpty(formKey)
      ? accountAvailableKeys
      : [...accountAvailableKeys, formKey];
  }, [accountKeys, accountAvailableKeys, selectedUser]);

  const handleAssignedObjectsSearch = (value: string) => {
    dispatch(
      getAccountObjectsThunk({
        accountId: accountId || "",
        params: {
          itemsPerPage: 50,
          page: 1,
          where: [{ field: "name", value, type: 0 }],
        },
      })
    );
  };

  const removeUserHandler = async () => {
    setDeleteLoading(true);

    const { type } = await dispatch(
      removeUserFromAccountThunk({ accountId, userId: selectedUser?.uniqueId })
    );

    if (type === "accounts/removeUserFromAccountThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName ===
          types.BackendEventsEnum.UserRemovedFromAccountEvent
        ) {
          setDeleteLoading(false);
          setDeleteUserModal(false);

          toast.success("User has been removed successfully.");

          dispatch(
            getAccountAssociatedUsersThunk({
              accountId: params.id || "",
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      toast.error("Server side error occured.");
      setDeleteLoading(false);
      setDeleteUserModal(false);
    }
  };

  const addUserHandler = async (
    values: types.IAddUser,
    formikHelpers: FormikHelpers<types.IAddUser>
  ) => {
    setIsSubmitting(true);
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(
      createUserAndAssociateToAccountThunk({
        accountId,
        values: { ...values, phone: `${values.countryCode}${values.phone}` },
      })
    );

    if (type === "accounts/createUserAndAssociateToAccountThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (event.eventName === types.BackendEventsEnum.UserCreatedEvent) {
          setIsSubmitting(false);
          toggleAddUserModal();

          toast.success("User has been created successfully.");

          dispatch(
            getAccountAssociatedUsersThunk({
              accountId: params.id || "",
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      toast.error("Server side error occured.");
      setIsSubmitting(false);
      formikHelpers.setSubmitting(false);
    }
  };

  const editUserHandler = async (
    values: types.IAddUser,
    formikHelpers: FormikHelpers<types.IAddUser>
  ) => {
    setIsSubmitting(true);
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(
      editUserFromAccountThunk({
        accountId,
        userId: values.uniqueId,
        data: { ...values, phone: `${values.countryCode}${values.phone}` },
      })
    );

    if (type === "accounts/editUserFromAccountThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (event.eventName === types.BackendEventsEnum.UserUpdatedEvent) {
          setIsSubmitting(false);
          setAddUserModal(false);

          toast.success("User has been updated successfully.");

          dispatch(
            getAccountAssociatedUsersThunk({
              accountId: params.id || "",
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      toast.error("Server side error occured.");
      setIsSubmitting(false);
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Box>
      <AddUserForm
        initialValues={selectedUser}
        open={addUserModal}
        onClose={toggleAddUserModal}
        onSubmit={selectedUser ? editUserHandler : addUserHandler}
        roles={roles ?? []}
        keys={filteredKeys ?? []}
        keysLoading={accountKeysLoading || accountAvailableKeysLoading}
        fetchObjectsOnDebounce={handleAssignedObjectsSearch}
        searchedObjects={accountObjects}
        objectsLoading={accountObjectsLoading}
        alarmPriority={ALARM_PRIORITY}
        submitting={isSubmitting}
        googlePredictatedPlacesLoading={googlePredictatedPlacesLoading}
        googlePredictatedPlaces={googlePredictatedPlaces}
        debounceFetchPlaces={(search: string) => {
          dispatch(getGooglePlacesThunk(search));
        }}
        theme={theme}
      />
      <DeleteConfirmationModal
        open={deleteUserModal}
        subTitle={
          "Do you really want to delete this user?\nThis process cannot bo undone"
        }
        title={"You are about to delete user"}
        onClose={() => setDeleteUserModal(false)}
        primaryBtnProps={{
          onClick: removeUserHandler,
          loading: deleteLoading,
        }}
      />
    </Box>
  );
};

export default AddUserFormWrapper;
