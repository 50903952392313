import { createAsyncThunk } from "@reduxjs/toolkit";
import { AlarmDesk, Objects } from "app/data/services";
import { setIsLockedOfAlarm, setAllAlarmsAsUnlocked } from "./alarmDeskSlice";
import { toast, types } from "@vilocnv/allsetra-core";
import {
  IAlarmReportTheft,
  IAlarmSendEmail,
  IAlarmSendSMS,
  IClearAlarm,
} from "app/data/types";
import { alarmParams } from "app/data/constants";
import { store } from "app/store";

export const getAllAlarmsThunk = createAsyncThunk(
  "alarmDesk/getAllAlarmsThunk",
  async () => {
    try {
      const response = await AlarmDesk.getAllAlarms();
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAlarmsByQuery = async (
  params: types.IRecordsAggregationBody
) => {
  try {
    const response = await AlarmDesk.getAlarmsByQuery(params);
    if (response.status === 200) {
      return response.data;
    }
  } catch (e: any) {
    console.error(e);
    throw new Error(e);
  }
};

export const getAlarmsByQueryThunk = createAsyncThunk(
  "alarmDesk/getAlarmsByQueryThunk",
  getAlarmsByQuery
);

export const getAlarmsByQuerySilentlyThunk = createAsyncThunk(
  "alarmDesk/getAlarmsByQuerySilentlyThunk",
  getAlarmsByQuery
);

export const getAlarmHistoryThunk = createAsyncThunk(
  "alarmDesk/getAlarmHistoryThunk",
  async (params: any) => {
    try {
      const response = await AlarmDesk.getAlarmHistory(params?.id);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const postLockAlarmThunk = createAsyncThunk(
  "alarmDesk/postLockAlarmThunk",
  async (
    { alarmId, lockedBy }: { alarmId: string; lockedBy: any },
    { dispatch }
  ) => {
    try {
      const response = await AlarmDesk.postLockAlarm(alarmId);
      const account = store.getState().rootReducer.dashboardReducer.account;

      if (response.status === 202) {
        dispatch(
          setIsLockedOfAlarm({
            alarmId,
            isLocked: true,
            lockedBy,
            loggedUserId: account?.uniqueId || "",
          })
        );
      }

      return response;
    } catch (e: any) {
      console.error(e);
      if (e.response.status === 409) {
        const message = "This alarm is already locked by another user.";

        toast.error(message);

        return {
          status: 409,
          message,
          data: e.response.data,
        };
      } else {
        const message = "Server side error occured while locking an alarm.";

        toast.error(message);

        return {
          status: 500,
          message,
          data: null,
        };
      }
    }
  }
);

export const postUnlockAlarmThunk = createAsyncThunk(
  "alarmDesk/postUnlockAlarmThunk",
  async (
    { alarmId, lockedBy }: { alarmId: string; lockedBy: any },
    { dispatch }
  ) => {
    try {
      const response = await AlarmDesk.postUnlockAlarm(alarmId);

      if (response.status === 202) {
        dispatch(setIsLockedOfAlarm({ alarmId, isLocked: false, lockedBy }));
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const postUnlockAllAlarmsThunk = createAsyncThunk(
  "alarmDesk/postUnlockAllAlarmsThunk",
  async (_, { dispatch }) => {
    try {
      const response = await AlarmDesk.postUnlockAllAlarms();

      if (response.status === 202) {
        dispatch(setAllAlarmsAsUnlocked());
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const postAlarmReportTheftThunk = createAsyncThunk(
  "alarmDesk/postAlarmReportTheftThunk",
  async ({ alarmId, data }: { alarmId: string; data: IAlarmReportTheft }) => {
    try {
      const response = await AlarmDesk.postAlarmReportTheft(alarmId, data);

      if (response.status === 202) {
        toast.success("Theft has been reported");
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const postClearAlarmThunk = createAsyncThunk(
  "alarmDesk/postClearAlarmThunk",
  async ({ alarmId, data }: { alarmId: string; data: IClearAlarm }) => {
    try {
      const response = await AlarmDesk.postClearAlarm(alarmId, data);
      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e?.response?.data?.title || e?.message);
      throw new Error(e);
    }
  }
);

export const postAlarmSendEmailThunk = createAsyncThunk(
  "alarmDesk/postAlarmSendEmailThunk",
  async ({ alarmId, data }: { alarmId: string; data: IAlarmSendEmail }) => {
    try {
      const response = await AlarmDesk.postAlarmSendEmail(alarmId, data);

      if (response.status === 202) {
        toast.success("Email has been send for the alarm");
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const postAlarmSendSMSThunk = createAsyncThunk(
  "alarmDesk/postAlarmSendSMSThunk",
  async ({ alarmId, data }: { alarmId: string; data: IAlarmSendSMS }) => {
    try {
      const response = await AlarmDesk.postAlarmSendSMS(alarmId, data);

      if (response.status === 202) {
        toast.success("SMS has been sent for the alarm");
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deleteCommentFromAlarmThunk = createAsyncThunk(
  "alarmDesk/deleteCommentFromAlarmThunk",
  async ({ objectId, commentId }: { objectId: string; commentId: string }) => {
    try {
      const response = await AlarmDesk.deleteCommentFromAlarm(
        objectId,
        commentId
      );

      if (response.status === 202) {
        toast.success("Comment has been deleted from the alarm");
      }
    } catch (e: any) {
      toast.error(e.message);
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSpecificAlarmThunk = createAsyncThunk(
  "alarmDesk/getSpecificAlarmThunk",
  async ({
    alarmId,
    isFromAlarmCreatedEvent = false,
  }: {
    alarmId: string;
    isFromAlarmCreatedEvent?: boolean;
  }) => {
    try {
      const response = await AlarmDesk.getSpecficAlarm(alarmId);

      if (response.status === 200) {
        return {
          data: response.data,
          isFromAlarmCreatedEvent,
        };
      }

      return {
        data: null,
        isFromAlarmCreatedEvent: false,
      };
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const countryWhiteListThunk = createAsyncThunk(
  "alarmDesk/CountryWhiteListThunk",
  async (
    {
      accountId,
      data,
    }: {
      accountId: string;
      data: any;
    },
    { dispatch }
  ) => {
    try {
      await AlarmDesk.editCountryWhiteList(accountId, data);
      dispatch(getAlarmsByQueryThunk(alarmParams));
      toast.success("Request Successful");
    } catch (e: any) {
      console.error(e);
      toast.error(e.message || "Something went wrong");
      throw new Error(e);
    }
  }
);

export const getCountryListThunk = createAsyncThunk(
  "alarmDesk/getCountryListThunk",
  async () => {
    try {
      const response = await AlarmDesk.getCountryWhiteList();

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      toast.error(e.message || "Something went wrong");
      throw new Error(e);
    }
  }
);

export const getAlarmPersonQs = createAsyncThunk(
  "alarmDesk/getAlarmPersonQs",
  async (userId: string) => {
    try {
      const response = await AlarmDesk.getAlarmPersonQsData(userId);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getActiveAlarmConfig = createAsyncThunk(
  "alarmDesk/getActiveAlarmConfig",
  async ({ objectId }: any) => {
    try {
      const response = await Objects.getAllAlarmsConfigByObjectId(objectId);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAccountGeozones = createAsyncThunk(
  "alarmDesk/getAccountGeozones",
  async (accountId: string) => {
    try {
      const response = await AlarmDesk.getAccountGeozones(accountId);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
