import { FC, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { ModalProps, types, toast } from "@vilocnv/allsetra-core";
import { Formik, Form, FormikHelpers } from "formik";

// DATA
import { useAppDispatch } from "hooks";
import { IClearAlarm } from "app/data/types";
import {
  clearAlarmInitialValues,
  clearAlarmValidationSchema,
} from "app/data/helpers";
import { postClearAlarmThunk } from "app/features";
import InnerForm from "./children/InnerForm";
import { SignalRService } from "app/data/services";

type Props = Omit<ModalProps, "title" | "children"> & {
  alarmId: string | null;
  selectedAlarm: types.IAlarm | null;
};

const ClearAlarmForm: FC<Props> = ({
  open,
  onClose,
  alarmId,
  selectedAlarm,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const formikHelpersRef = useRef<any>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [hasAlarmBeenCleared, setHasAlarmBeenCleared] =
    useState<boolean>(false);
  const [checkAlarmIsCleaned, setCheckAlarmIsCleaned] =
    useState<boolean>(false);

  useEffect(() => {
    let alarmClearedHandled = false;

    const eventHandler = (event: any) => {
      if (
        event.eventName === types.BackendEventsEnum.AlarmClearedEvent &&
        !alarmClearedHandled
      ) {
        alarmClearedHandled = true;

        setHasAlarmBeenCleared(true);
        setCheckAlarmIsCleaned(true);

        toast.success("Alarm has been cleared successfully.");
      }
    };

    SignalRService.hubConnection?.on("EventRaised", eventHandler);

    return () => {
      // disconnect when component did unmount
      SignalRService.hubConnection?.off("EventRaised", eventHandler);
    };
  }, []);

  useEffect(() => {
    if (checkAlarmIsCleaned) {
      if (!hasAlarmBeenCleared) {
        toast.success("Alarm clear is being processed");
      }

      setIsProcessing(false);
      onClose();
      if (formikHelpersRef.current) {
        formikHelpersRef.current.resetForm();
        formikHelpersRef.current.setSubmitting(false);
      }

      setCheckAlarmIsCleaned(false);
    }
  }, [checkAlarmIsCleaned, hasAlarmBeenCleared]);

  const onSubmitHandler = async (
    values: IClearAlarm,
    formikHelpers: FormikHelpers<IClearAlarm>
  ) => {
    formikHelpersRef.current = formikHelpers;

    if (alarmId && selectedAlarm?.uniqueId) {
      setIsProcessing(true);
      formikHelpers.setSubmitting(true);

      await dispatch(
        postClearAlarmThunk({
          alarmId: alarmId || selectedAlarm?.uniqueId,
          data: values,
        })
      );

      await wait(1000);

      setCheckAlarmIsCleaned(true);
    } else {
      toast.error("Alarm Id not available");
    }
  };

  const wait = async (time: number): Promise<void> => {
    return await new Promise((resolve) => setTimeout(resolve, time));
  };

  return (
    <Box>
      <Formik
        initialValues={clearAlarmInitialValues}
        validationSchema={clearAlarmValidationSchema}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validateOnMount
      >
        <Form>
          <InnerForm
            open={open}
            onClose={onClose}
            isProcessing={isProcessing}
            {...rest}
          />
        </Form>
      </Formik>
    </Box>
  );
};

export default ClearAlarmForm;
