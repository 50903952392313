import React, { FC, useState, useCallback, useEffect } from "react";
import { isEmpty } from "lodash";
import {
  Autocomplete,
  Paper,
  TextField,
  AutocompleteRenderGetTagProps,
  AutocompleteChangeReason,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { useDebouncedCallback } from "use-debounce";
import {
  AutocompleteChip,
  Loader,
  AutoCompleteFieldWrapper,
  InputLabelSpan,
  InputErrorSpan,
  IsRequiredSpan,
} from "./AutocompleteSearchField.styled";
import { SxProps, Theme } from "@mui/material";

interface SelectInputFieldProps {
  name: string;
  value?: string | any;
  label?: string;
  placeholder?: string;
  error?: string;
  renderOption?: any;

  onChange?: (value: any) => void;
  onBlur?: (event: any) => void;
  onSearchTextChange?: (value: string) => void;
  onClearText?: () => void;

  options: Array<Object>;
  optionLabelKey?: string;
  optionValueKey?: string;
  emptyOptionsText?: string;

  loading?: boolean;
  searchable?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  multiDropdown?: boolean;
  isGroups?: boolean;
  isFilter?: boolean;
  freeSolo?: boolean;
  filterOptions?: (options: any, params: any) => [];
  onAddNewOption?: (value: any) => void;
  theme?: Theme;
  infiniteScroll?: boolean;
}

const INITIAL_OPTIONS_SIZE = 100;

const AutocompleteSearchField: FC<SelectInputFieldProps> = ({
  name,
  value,
  label,
  placeholder,
  error,
  required,
  onChange,
  onBlur,
  onSearchTextChange,
  onClearText,
  options,
  optionLabelKey = "label",
  optionValueKey = "id",
  emptyOptionsText = "No options were found",
  loading,
  multiple = false,
  disabled,
  readOnly,
  fullWidth = false,
  sx,
  isGroups,
  isFilter,
  freeSolo,
  filterOptions,
  onAddNewOption,
  infiniteScroll,
  renderOption,
  ...rest
}) => {
  const [innerValue, setInnerValue] = useState(multiple ? [] : "");
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loadedOptions, setLoadedOptions] = useState(
    options.slice(0, INITIAL_OPTIONS_SIZE)
  );
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [page, setPage] = useState(1);

  const debouncedHandleOnChange = useDebouncedCallback((event) => {
    if (onSearchTextChange) onSearchTextChange(event.target.value);
  }, 500);

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    if (freeSolo && reason === "createOption" && onAddNewOption) {
      onAddNewOption(value);
      return;
    }

    const formattedValue = Array.isArray(value)
      ? value.map((item) =>
          typeof item === "object" ? item[optionValueKey] : item
        )
      : typeof value === "object" && value !== null
      ? value[optionValueKey]
      : value;

    setInnerValue(formattedValue);

    if (onChange) onChange(formattedValue);

    if (onClearText && reason === "clear") {
      if (infiniteScroll) {
        resetLoadedOptionsState();
        setInnerValue(multiple ? [] : "");
        setSearchValue("");
      }
      onClearText();
    }
  };

  const handleSearchTextChange = useCallback(
    (event: any) => {
      setSearchValue(event.target.value);
      debouncedHandleOnChange(event);

      if (infiniteScroll) {
        if (event.target.value) {
          const newFilteredOptions = options.filter((option: any) =>
            option[optionLabelKey]
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
          );

          setFilteredOptions(newFilteredOptions);
          setLoadedOptions(newFilteredOptions.slice(0, INITIAL_OPTIONS_SIZE));
          setPage(1);
        } else {
          resetLoadedOptionsState();
        }
      }
    },
    [debouncedHandleOnChange]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (options.length > 0 || loading) {
      setIsOpen(true);
    }
  }, [options, loading]);

  const resetLoadedOptionsState = () => {
    setFilteredOptions(options);
    setLoadedOptions(options.slice(0, INITIAL_OPTIONS_SIZE));
    setPage(1);
  };

  const handleClearClick = () => {
    if (infiniteScroll) {
      resetLoadedOptionsState();
      setInnerValue(multiple ? [] : "");
      setSearchValue("");
      if (onClearText) {
        onClearText();
      }
    }
  };

  const getOptionLabel = (optionValue: any) => {
    const option: any = options.find(
      (opt: any) => opt[optionValueKey] === optionValue
    );

    if (option && typeof option === "object") {
      return option[optionLabelKey];
    }

    return typeof optionValue === "object"
      ? optionValue[optionLabelKey]
      : optionValue;
  };

  const isOptionEqualToValue = (option: any, value: any) => {
    return option[optionValueKey] === value;
  };

  const renderTags = (
    value: any,
    getTagProps: AutocompleteRenderGetTagProps
  ) => {
    if (isEmpty(value)) return undefined;

    return value.map((selectedValue: any, index: number) => {
      const selectedOption: any = options.find(
        (opt: any) => opt[optionValueKey] === selectedValue
      );

      return (
        <AutocompleteChip
          label={selectedOption ? selectedOption[optionLabelKey] : ""}
          deleteIcon={<HighlightOff />}
          {...getTagProps({ index })}
        />
      );
    });
  };

  return (
    <AutoCompleteFieldWrapper
      isFilter={isFilter}
      fullWidth={fullWidth}
      sx={sx}
      isGroups={isGroups}
    >
      {label && (
        <InputLabelSpan>
          {label} {required && <IsRequiredSpan>*</IsRequiredSpan>}
        </InputLabelSpan>
      )}
      <Autocomplete
        value={value || innerValue}
        options={infiniteScroll ? loadedOptions : options}
        multiple={multiple}
        disabled={disabled}
        readOnly={readOnly}
        onChange={handleChange}
        onBlur={onBlur}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        PaperComponent={(props) => (
          <Paper
            {...props}
            elevation={6}
            style={{ overflow: "hidden", marginTop: "8px" }}
          />
        )}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        clearIcon={
          <HighlightOff
            onClick={handleClearClick}
            style={{ color: "#959EB2" }}
          />
        }
        ChipProps={{
          size: "small",
          deleteIcon: <HighlightOff />,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            value={searchValue}
            disabled={disabled}
            required={required}
            onChange={handleSearchTextChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
          />
        )}
        renderTags={renderTags}
        noOptionsText={loading ? <Loader /> : <div>{emptyOptionsText}</div>}
        filterSelectedOptions
        freeSolo
        filterOptions={filterOptions}
        loading={loading}
        autoHighlight={true}
        renderOption={renderOption}
        openOnFocus
        {...rest}
      />
      {error && <InputErrorSpan>{error}</InputErrorSpan>}
    </AutoCompleteFieldWrapper>
  );
};

export default AutocompleteSearchField;
