import {
  styled,
  Autocomplete,
  Box,
  Chip,
  Select,
  CircularProgress,
  MenuItem,
} from "@mui/material";

export const AutoCompleteFieldWrapper = styled(Box)<{
  fullWidth?: boolean;
  isGroups?: boolean;
  isFilter?: boolean;
}>(({ fullWidth, isFilter, isGroups, theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: fullWidth ? "100%" : "380px",
  borderRadius: "6px",
  border: isFilter ? `1px solid #00B2A3` : "none",
  "& .MuiInputBase-input": {
    height: "15px",
  },
  "& .MuiAutocomplete-endAdornment": {
    top: "8px !important",
  },
  "& fieldset": {
    border: "1px solid #DFE5F4",
  },

  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
  },

  "&:hover .MuiInputBase-root > fieldset": {
    borderColor: theme.palette.primary.main,
  },

  "& .MuiAutocomplete-root": isGroups
    ? {
        maxHeight: "100px",
        overflowY: "auto",
        border: "1px solid rgb(48 109 243)",
        zIndex: "9999",
        position: "relative",
        borderRadius: "6px",
      }
    : {},
}));

export const SelectFieldWrapper = styled(Box)<{
  fullWidth?: boolean;
  multiSelectDropdown?: boolean;
}>(({ fullWidth, multiSelectDropdown }) => ({
  display: "flex",
  flexDirection: "column",
  width: fullWidth ? "100%" : multiSelectDropdown ? "40px" : "380px",

  "& .MuiInputBase-root": {
    height: "48px",
  },

  "& div": {
    borderRadius: "6px",
  },

  "& .MuiPaper-root-MuiMenu-paper-MuiPopover-paper": {
    backgroundColor: "green !important",
  },
}));

export const AutocompleteChip = styled(Chip)({
  backgroundColor: "#EDEDFF",
  borderRadius: 4,
  color: "#39169E",
  fontSize: 12,
  lineHeight: "132%",
  fontWeight: 400,
});

export const SelectMui = styled(Select)(({ theme }) => ({
  "& fieldset": {
    border: "1px solid #DFE5F4",
  },

  "&:hover .MuiOutlinedInput-notchedOutline, &:active .MuiOutlinedInput-notchedOutline":
    {
      borderColor: theme.palette.primary.main,
    },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    // Focused state
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const AutocompleteWrapper = styled(Autocomplete)({
  "& fieldset": {
    border: "1px solid #DFE5F4",
  },
  "& .MuiAutocomplete-endAdornment": {
    top: "8px !important",
  },
});

export const Loader = styled(CircularProgress)(({ theme }) => ({
  display: "flex",
  width: "16px !important",
  height: "16px !important",
  margin: "0px auto",
  color: theme.palette.primary.main,
}));

export const SelectMuiMultiple = styled(Select)(({ theme }) => ({
  "& fieldset": {
    border: "1px solid #DFE5F4",
  },

  "&:hover .MuiOutlinedInput-notchedOutline, &:active .MuiOutlinedInput-notchedOutline":
    {
      borderColor: theme.palette.primary.main, // Change border color to pink on hover and active states
    },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    // Focused state
    border: `1px solid ${theme.palette.primary.main}`,
  },

  "& svg": {
    width: "16px",
    height: "16px",
    color: "#646D7B",
    right: "12px",
    top: "calc(50% - 0.4em)",
  },

  "& div": {
    padding: "14px 16px",
  },
}));

export const MenuItemWrapper = styled(MenuItem)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  lineHeight: "0px",
});

export const InputLabelSpan = styled("span")({
  marginBottom: 4,
  color: "#1D1F2B",
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "132%",
});

export const InputErrorSpan = styled("span")({
  marginTop: 4,
  color: "#CC1010",
  fontWeight: 400,
  fontSize: 12,
  lineHeight: "132%",
});

export const IsRequiredSpan = styled("span")(({ theme }) => ({
  color: theme.palette.error.main,
}));
