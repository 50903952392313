import { FC, useState, useCallback, useEffect, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { Topbar, SelectInputField } from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector, useDispatchOnMount } from "hooks";
import {
  getAlarmsByQueryThunk,
  postUnlockAllAlarmsThunk,
  clearObjectData,
  getMinimalObjectsByQueryThunk,
} from "app/features";
import {
  selectAlarmDeskState,
  selectQueriedObjectsState,
} from "app/data/selectors";
import AlarmDeskTableGrid from "components/common/AlarmDeskTableGrid";
import { SearchFieldWrapper } from "components/common/Map/Map.styled";
import { isEmpty } from "lodash";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { alarmParams } from "app/data/constants";
import { useTranslation } from "react-i18next";
import { AccountsIcon } from "assets/icons";
import { ObjectsSvg } from "assets/icons";
import {
  SearchItem,
  SearchItemText,
} from "components/common/SearchItem/SearchItem.styled";
import AutocompleteSearchField from "components/ui/AutocompleteSearchField/AutocompleteSearchField";

const AlarmDesk: FC = () => {
  const [isHydrated, setIsHydrated] = useState(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Global State
  const { loading, alarms } = useAppSelector(selectAlarmDeskState);

  const { objectQueryloading, objectQueryList } = useAppSelector(
    selectQueriedObjectsState
  );

  // Local State
  const [searchText, setSearchText] = useState<string>("");
  const [filteredObjects, setFilteredObjects] = useState<any>([]);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  useEffect(() => {
    if (isHydrated) {
      dispatch(getAlarmsByQueryThunk(alarmParams));

      onClearText();
    }

    return () => {
      dispatch(postUnlockAllAlarmsThunk());
    };
  }, [isHydrated]);

  const debouncedHandleOnChange = useDebouncedCallback(() => {
    if (isEmpty(searchText)) return;
    dispatch(
      getMinimalObjectsByQueryThunk({
        itemsPerPage: 10,
        page: 1,
        where: [{ field: "Term", value: searchText, type: 0 }],
      })
    );
  }, 600);

  const handleChange = (value: string) => {
    setSearchText(value);
  };

  const updateFilteredObjects = (results: any[]) => {
    const result: any = [];
    results &&
      results?.map((item: any) => {
        if (item?.objectName && item?.objectId) {
          result.push({
            name: item?.objectName,
            uniqueId: item?.objectId,
            type: "object",
          });
        }
        if (item?.accountName && item?.accountId) {
          result.push({
            name: item?.accountName,
            uniqueId: item?.accountId,
            type: "account",
          });
        }
      });
    setFilteredObjects(result);
    // return result;
  };

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      // @ts-ignore
      const value = event?.target?.value;
      if (event.key === "Enter" && !isEmpty(value) && !isEmpty(searchText)) {
        setFilteredObjects([]);
        const { payload, type } = await dispatch(
          getMinimalObjectsByQueryThunk({
            itemsPerPage: 10,
            page: 1,
            where: [{ field: "Term", value: value, type: 0 }],
          })
        );

        if (type === "objects/getMinimalObjectsByQueryThunk/fulfilled") {
          updateFilteredObjects(payload?.results);
        }
      }
    },
    [dispatch, searchText]
  );

  const onDropDownSelect = (option: any) => {
    if (option?.type === "object") {
      navigate("/dashboard/alarm-history/" + option?.uniqueId);
    }
    if (option?.type === "account") {
      navigate("/dashboard/account-manager/details/" + option?.uniqueId);
    }
  };

  const onClearText = () => {
    dispatch(clearObjectData());
  };

  const renderSearchItem = (option: any) => {
    return (
      <SearchItem onClick={() => onDropDownSelect(option)}>
        <Box mt={0.5} mx={1}>
          {option.type === "object" ? <ObjectsSvg /> : <AccountsIcon />}
        </Box>
        <SearchItemText>{option.name}</SearchItemText>
      </SearchItem>
    );
  };

  return (
    <Box>
      <Topbar theme={theme} title={t("alarmDesk.title")}>
        <SearchFieldWrapper>
          <AutocompleteSearchField
            loading={objectQueryloading}
            name="search"
            placeholder={t("alarmDesk.labels.search")}
            options={filteredObjects}
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            onSearchTextChange={handleChange}
            onChange={onDropDownSelect}
            searchable
            emptyOptionsText={"Type to search..."}
            onClearText={onClearText}
            onBlur={onClearText}
            // @ts-ignore
            onKeyDown={handleKeyDown}
            // @ts-ignore
            renderOption={(props: any, option: any) => renderSearchItem(option)}
          />
        </SearchFieldWrapper>
      </Topbar>
      <AlarmDeskTableGrid data={alarms} loading={loading} fromDashboard />
    </Box>
  );
};

export default AlarmDesk;
