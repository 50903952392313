import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Table, types, useDispatchOnParams } from "@vilocnv/allsetra-core";
import AssignUserForm from "components/forms/accounts/AssignUserForm/AssignUserForm";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getAccountAssociatedUsersThunk,
  resetAccountObjects,
  resetGooglePredictatedPlaces,
} from "app/features";
import { selectAccountUsers, selectAllRoles } from "app/data/selectors";
import { ACCOUNT_USERS_TABLE_COLUMNS } from "app/data/constants";
import AddUserFormWrapper from "components/forms/accounts/AddUserFormWrapper/AddUserFormWrapper";

interface Props {
  accountId: string | null;
}

const AccountUsersSection: FC<Props> = ({ accountId }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { id } = params;

  // Global State
  const { accountUsers, accountUsersLoading, totalRecords } =
    useAppSelector(selectAccountUsers);
  const roles = useAppSelector(selectAllRoles);

  // Local State
  const [assignUserModal, setAssignUserModal] = useState<boolean>(false);
  const [addUserModal, setAddUserModal] = useState<boolean>(false);
  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  useDispatchOnParams(getAccountAssociatedUsersThunk, {
    searchByField: "user.email",
    args: { accountId: id === accountId ? accountId : id || "" },
  });

  const toggleAssignUserModal = () => setAssignUserModal(!assignUserModal);

  const toggleAddUserModal = () => {
    setSelectedUser(null);
    setAddUserModal(!addUserModal);
  };

  const handleEditClick = (user: types.IAddUser) => {
    dispatch(resetGooglePredictatedPlaces());
    dispatch(resetAccountObjects());
    setAddUserModal(true);
    setSelectedUser(user);
  };

  const handleDeleteClick = (user: any) => {
    setDeleteUserModal(true);
    setSelectedUser(user);
  };

  return (
    <Box>
      <Table
        columns={ACCOUNT_USERS_TABLE_COLUMNS(roles)}
        data={accountUsers}
        progressPending={accountUsersLoading}
        paginationTotalRows={totalRecords}
        cellActions={[
          { name: "Edit User", onClick: handleEditClick },
          { name: "Remove User", onClick: handleDeleteClick },
        ]}
        searchPlaceholder="Search user"
        primaryButton={{
          id: "add",
          text: "Add user",
          variant: "outlined",
          startIcon: <AddIcon />,
          onClick: toggleAddUserModal,
        }}
        secondaryButton={{
          id: "assign",
          text: "Assign user",
          variant: "outlined",
          onClick: toggleAssignUserModal,
        }}
      />
      <AssignUserForm
        open={assignUserModal}
        onClose={toggleAssignUserModal}
        accountId={accountId}
        roles={roles}
      />
      <AddUserFormWrapper
        toggleAddUserModal={toggleAddUserModal}
        selectedUser={selectedUser}
        accountId={accountId}
        addUserModal={addUserModal}
        setAddUserModal={setAddUserModal}
        deleteUserModal={deleteUserModal}
        setDeleteUserModal={setDeleteUserModal}
      />
    </Box>
  );
};

export default AccountUsersSection;
