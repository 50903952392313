import { useEffect, useRef } from "react";
import { useAppDispatch } from "hooks";
import { all, compose, isNil, not } from "ramda";

const hasAllArgs = all(compose(not, isNil));

/**
 * Calls fetch action creator if and only if all the argument is present.
 */
export default (actionCreator: (...args: any) => any, ...args: any[]) => {
  const dispatch = useAppDispatch();
  const isFetching = useRef(false);

  const call = async () => {
    isFetching.current = true;
    await dispatch(actionCreator(...args));
    isFetching.current = false;
  };

  useEffect(() => {
    if (hasAllArgs(args) && isFetching.current === false) {
      call();
    }
  }, [actionCreator, dispatch, ...args]);
};
